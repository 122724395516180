.searchResults {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 22px;
}

.searchResults img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 2px;
}

.searchResults img:first-child {
  margin-right: 11px;
}

.searchResults a {
  text-decoration: none;
  color: black;
  text-align: center;
  margin-bottom: 1em;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  margin-bottom: 20px;
}

.searchResults p {
  padding: 11px 22px;
}

.searchResults .needle {
  height: 12px;
  width: 14px;
  margin-top: -4px;
}
.searchResults p {
  font-size: 14px;
}

@media screen and (min-width: 800px) {
  .searchResults {
    background: white;
    padding: 55px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .searchResults a {
    text-align: left;
  }

  .searchResults p {
    font-size: 14px;
  }

  .searchResults img {
    width: 100%;
  }

  .searchResults .needle {
    height: 12px;
    width: 14px;
    margin-top: -4px;
  }
}
